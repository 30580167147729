import React, { useState } from "react";

const closeIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="#262626"
    />
  </svg>
);

const arrow = (
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.825195 9.31667L4.64186 5.5L0.825195 1.675L2.0002 0.5L7.00019 5.5L2.0002 10.5L0.825195 9.31667Z"
      fill="#262626"
    />
  </svg>
);

const CategoriesModal = ({ overdue, categories, template, checkout }) => {
  const [showModal, setShowModal] = useState(false);

  const isDesktop = window.matchMedia("(min-width: 980px)").matches;
  const gridColumn = template && isDesktop ? template.headers.length + 1 : 2;

  const rowStyle = {
    gridTemplateColumns: `repeat(${gridColumn}, 1fr)`,
  };

  return (
    <>
      <button
        type="button"
        disabled={overdue}
        onClick={() => setShowModal(true)}
      >
        Inscribirse
      </button>

      {showModal && (
        <div className="modal">
          <div className="categories-modal">
            <div className="categories-modal__header">
              <h3>Elegir categoría</h3>
              <button type="button" onClick={() => setShowModal(false)}>
                {closeIcon}
              </button>
            </div>
            <div className="categories-modal__body">
              <div className="categories-modal__body__table">
                {template && (
                  <>
                    <div
                      style={rowStyle}
                      className="categories-modal__body__table__row --desktop"
                    >
                      {template.headers.map((h) => (
                        <span key={h}>{h}</span>
                      ))}
                      <span />
                    </div>
                    {template.categories.map((c, item) => (
                      <label
                        style={rowStyle}
                        className="categories-modal__body__table__row"
                      >
                        {c.map((i) => (
                          <span>{i}</span>
                        ))}
                        <div>
                          <span>
                            $
                            {categories.find((ctgy) => ctgy.name == c[0]).price}
                          </span>
                          {arrow}
                        </div>
                        <input
                          type="radio"
                          name="category"
                          className="d-none"
                        />
                        <div className="row-info">
                          <ul>
                            {template.categories[item].map(
                              (i, index) =>
                                index > 0 && (
                                  <li>
                                    <strong>{template.headers[index]}:</strong>{" "}
                                    {i}
                                  </li>
                                )
                            )}
                          </ul>
                          <a href={`${checkout}${c[0]}`}>Realizar pago</a>
                        </div>
                      </label>
                    ))}
                  </>
                )}
                {!template && (
                  <>
                    <div className="categories-modal__body__table__row --desktop">
                      <span>Nombre</span>
                    </div>
                    {categories.map((c) => (
                      <label
                        className="categories-modal__body__table__row"
                        style={rowStyle}
                      >
                        <span>{c.name}</span>
                        <div>
                          <span>{c.price}</span>
                          {arrow}
                        </div>
                        <input
                          type="radio"
                          name="category"
                          className="d-none"
                        />
                        <div className="row-info">
                          <a href={`${checkout}${c.name}`}>Realizar pago</a>
                        </div>
                      </label>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CategoriesModal;
